import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { tablet } from 'styles/breakpoints';
import { DynamicImage, PrimaryButton } from 'components';
import Verified from 'assets/icons/verified-results-review.svg';
import { decideWeightDisplayValue } from 'utils/units';
import { useSelector } from 'react-redux';
import { AppState } from 'state/types';

interface ReviewItem {
  review: string;
  name: string;
  img: string;
  kgConvert: number;
}

interface ReviewsTypes {
  buttonTitle: string;
  list: ReviewItem[];
  onContinue: () => void;
}

const ResultsReviews: FC<ReviewsTypes> = ({
  buttonTitle,
  list,
  onContinue,
}) => {
  const [loading, setLoading] = useState(false);

  const nextStep = () => {
    setLoading(true);
    onContinue();
  };
  const quiz_answers = useSelector(
    (state: AppState) => state.user?.quiz_answers,
  );

  return (
    <Container>
      <ListContainer>
        {list.map((item, index) => {
          const weight = decideWeightDisplayValue(
            item?.kgConvert,
            quiz_answers?.units_of_measure,
          );
          return (
            <ListItem key={index}>
              <ImgContainer>
                <StyledImg src={item.img} alt="Carnimeat users" />
              </ImgContainer>
              <TextContainer>
                <ListText>{item.review.replace('${kg}', weight)}</ListText>
                <BottomContainer>
                  <Name>{item.name}</Name>
                  <Verified />
                </BottomContainer>
              </TextContainer>
            </ListItem>
          );
        })}
      </ListContainer>
      <ButtonContainer>
        <ContinueButtonStyled onClick={nextStep} loading={loading}>
          {buttonTitle}
        </ContinueButtonStyled>
      </ButtonContainer>
    </Container>
  );
};

export default ResultsReviews;

const Container = styled.div`
  max-width: 30.75rem;
  margin: 0 auto;
  width: 100%;
  @media ${tablet} {
  }
`;

const TextContainer = styled.section`
  display: flex;
  padding: 0.75rem 0.75rem 0.75rem 0rem;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
`;

const ButtonContainer = styled.section`
  width: 100%;
  @media ${tablet} {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem 1rem;
    width: 100%;
    border-top: 1px solid #f5f5f5;
    background: #fff;
  }
`;

const ContinueButtonStyled = styled(PrimaryButton)`
  margin: 1.5rem auto 0;
  @media ${tablet} {
    max-width: 28.75rem;
    margin: 0 auto;
  }
`;
const ListContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
`;

const ListItem = styled.section`
  display: flex;
  gap: 1rem;
  border-radius: 0.75rem;
  overflow: hidden;
  border: 1px solid #f5f5f5;
  background: #f9f9f9;
`;

const ListText = styled.p`
  color: #000;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
`;

const Name = styled.p`
  color: #000;
  text-align: center;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
`;

const BottomContainer = styled.section`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const ImgContainer = styled.section`
  width: 6.125rem;
`;

const StyledImg = styled(DynamicImage)`
  width: 6.125rem;
  height: 100%;
`;
