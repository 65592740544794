import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { tablet } from 'styles/breakpoints';
import { PrimaryButton } from 'components';
import Checkmark from 'assets/icons/green-checkmark.svg';
import Mark from 'assets/icons/extra-red-mark.svg';
import Arrow from 'assets/icons/quiz/personal-summary-arrow.svg';

interface FatBurningTypes {
  buttonTitle: string;
  nowTitle: string;
  topTitle: string;
  levelTexts: string[];
  you: string;
  nowList: string[];
  futureTitle: string;
  futureList: string[];
  onContinue: () => void;
}
const FatBurning: FC<FatBurningTypes> = ({
  buttonTitle,
  nowTitle,
  topTitle,
  levelTexts,
  you,
  nowList,
  futureTitle,
  futureList,
  onContinue,
}) => {
  const [animate, setAnimate] = useState(false);
  const [renderCount, setRenderCount] = useState(0);
  const [leftPosition, setLeftPosition] = useState('-5%');

  useEffect(() => {
    setRenderCount(prev => prev + 1);

    setAnimate(false);
    setTimeout(() => {
      setLeftPosition('5%');
      setAnimate(true);
    }, 10);
  }, []);
  return (
    <Container>
      <Content>
        <TopTitleContainer>
          <SectionTitle>{topTitle}</SectionTitle>
        </TopTitleContainer>
        <ChartContainer2>
          <BubbleContainer
            animate={animate}
            key={renderCount}
            leftPosition={leftPosition}
          >
            <YouText>
              {you}
              <Arrow />
            </YouText>
            <Bubble />
          </BubbleContainer>

          <Line2 />
          <Levels>
            {levelTexts.map((item, index) => (
              <LevelText key={index}>{item}</LevelText>
            ))}
          </Levels>
        </ChartContainer2>
      </Content>

      <ChartContainer>
        <LeftSide>
          <LeftTitle>
            <Mark /> {nowTitle}
          </LeftTitle>
          <ListBox>
            {nowList.map((item: string, index: number) => (
              <>
                <ListItem
                  key={index}
                  dangerouslySetInnerHTML={{ __html: item }}
                />
                {index < nowList.length - 1 && <Line />}
              </>
            ))}
          </ListBox>
        </LeftSide>

        <RightSide>
          <RightTitle>
            <Checkmark />
            {futureTitle}
          </RightTitle>
          <ListBox>
            {futureList.map((item: string, index: number) => (
              <>
                <ListItem
                  key={index}
                  dangerouslySetInnerHTML={{ __html: item }}
                />
                {index < futureList.length - 1 && <Line />}
              </>
            ))}
          </ListBox>
        </RightSide>
      </ChartContainer>
      <ButtonContainer>
        <ContinueButtonStyled onClick={onContinue}>
          {buttonTitle}
        </ContinueButtonStyled>
      </ButtonContainer>
    </Container>
  );
};

export default FatBurning;

const SectionTitle = styled.p`
  color: #000;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  @media ${tablet} {
    font-size: 1.125rem;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: 100%;
  max-width: 28.75rem;
  display: flex;
  padding: 1rem;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  margin: 0 auto 1.5rem;
  border-radius: 0.75rem;
  border: 1px solid #f5f5f5;
  background: #f9f9f9;
  position: relative;
`;

const TopTitleContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const Container = styled.div`
  max-width: 28.75rem;
  margin: 0 auto;
  width: 100%;
  @media ${tablet} {
  }
`;

const ButtonContainer = styled.section`
  width: 100%;
  @media ${tablet} {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem 1rem;
    width: 100%;
    border-top: 1px solid #f5f5f5;
    background: #fff;
  }
`;

const ContinueButtonStyled = styled(PrimaryButton)`
  margin: 1.5rem auto 0;
  @media ${tablet} {
    max-width: 28.75rem;
    margin: 0 auto;
  }
`;

const ChartContainer = styled.section`
  display: flex;
  width: 100%;
  margin-top: 1.5rem;
  @media ${tablet} {
    margin-top: 1rem;
  }
`;

const ChartContainer2 = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 3.13rem;
  @media ${tablet} {
    margin-top: 2.62rem;
  }
`;

const Line2 = styled.div`
  width: 100%;
  height: 0.52006rem;
  border-radius: 625rem;
  background: linear-gradient(90deg, #dc4b4b 0%, #f7d158 49.11%, #52aa48 100%);
`;

const Levels = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 0.5rem;
  margin: 0 1rem;
`;

const LevelText = styled.p`
  color: #000;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  @media ${tablet} {
    font-size: 0.75rem;
  }
`;

const BubbleContainer = styled.div<{ animate: boolean; leftPosition: string }>`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.57rem;
  top: -40px;
  left: ${({ leftPosition }) => leftPosition};
  transition: left 0.5s ease-out;
  @media ${tablet} {
    top: -39px;
  }
`;

const YouText = styled.div`
  display: inline-flex;
  padding: 0.25rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.1875rem;
  background: #dc4b4b;
  color: #fff;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  position: relative;
  text-wrap: nowrap;
  svg {
    position: absolute;
    bottom: -6px;
    path {
      fill: #dc4b4b;
    }
  }
  @media ${tablet} {
    font-size: 0.75rem;
  }
`;

const LeftSide = styled.section`
  width: 100%;
  display: flex;
  padding: 1rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
  border-radius: 0.75rem 0rem 0rem 0.75rem;
  border: 1px solid #dc4b4b;
  border-right: 0;
  background: #fceded;
`;

const RightSide = styled.section`
  width: 100%;
  display: flex;
  padding: 1rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
  border-radius: 0rem 0.75rem 0.75rem 0rem;
  border: 1px solid #3fa134;
  border-left: none;
  background: #e1fcda;
`;

const LeftTitle = styled.h3`
  display: flex;
  align-items: center;
  gap: 0.375rem;
  color: #dc4b4b;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
  @media ${tablet} {
    font-size: 1rem;
  }
`;

const RightTitle = styled(LeftTitle)`
  color: #3fa134;
`;

const ListBox = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.75rem;
  width: 100%;
`;

const ListItem = styled.p`
  color: #000;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  @media ${tablet} {
    span {
      display: block;
    }
  }
`;

const Bubble = styled.div`
  width: 1rem;
  height: 1rem;
  background: #fff;
  border: 4.878px solid #dc4b4b;
  border-radius: 3rem;
`;

const Line = styled.section`
  height: 1px;
  width: 100%;
  background: rgba(119, 119, 119, 0.1);
`;
