import greenCheck from 'assets/icons/green-checkmark.svg';
import blueMark from 'assets/icons/blue-mark.svg';
import redMark from 'assets/icons/red-mark.svg';
import yellowMark from 'assets/icons/yellow-mark.svg';

export default {
  greenCheck,
  blueMark,
  redMark,
  yellowMark,
};
